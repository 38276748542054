/* .smpNavbar { */
    /* min-height: 15%; */
/* } */
.smpContainer {
    height: 100%;
}

.logo {
    width: 23%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 1rem;
    border-radius: .7rem;
    background-color: rgba(255, 255, 255, 0.795);
    box-shadow: 0 5px 200px;
}

.logo-nav {
    width: 150px;
    align-self: center;
}