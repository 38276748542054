.schooldrive {
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
/* .schooldrive .background {
    position: absolute;
    width: 100%;
    height: 50vh;
    background: var(--primary-color);
    z-index: -1;
} */
  
.schooldrive .card {
    width: 90vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22vh;
    background: rgb(255, 255, 255);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    box-shadow: 0 0 2px black;
}
  
.schooldrive .card .card-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
  
.schooldrive .card #title{
    margin-bottom: 2rem;
}
  
.schooldrive .card #subtitle{
    width: 65%;
    margin: 0 auto;
    color: var(--title-color);
    padding-bottom: .6rem;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
}
  
.schooldrive .card .card-section p {
    color: #5c5c5c;
    line-height: 1.4rem;
}

.schooldrive .card .card-section p:nth-child(5) {
  color: var(--primary-color);
}
  
.schooldrive .card .card-section #substate{
    color: #00b436;
}

.schooldrive .card-section #cars h5{
  margin-bottom: 0.35rem;
}
  
.schooldrive .card-section span{
    margin-left: auto;
    margin-right: 0;
    color: #6d6d6d;
}
