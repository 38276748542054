.drivinglicenses {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.drivinglicenses .card {
    width: 90vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22vh;
    background: #fff;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    box-shadow: 0 0 2px black;
}
  
.drivinglicenses .card .card-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.drivinglicenses .card #title{
    margin-bottom: 2rem;
}
  
.drivinglicenses .card #subtitle{
    width: 65%;
    margin: 0 auto;
    color: var(--title-color);
    padding-bottom: .6rem;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
}

.drivinglicenses .card .card-section p {
    color: #5c5c5c;
    line-height: 1.4rem;
}

.drivinglicenses .card .card-section p:nth-child(5) {
    color: var(--primary-color);
}

#modal-modal-title{
    width: 40%;
    text-align: center;
    border-bottom: 2px solid var(--primary-color);
    margin: 0 auto;
}