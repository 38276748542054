.dashboard {
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard .background {
    position: absolute;
    width: 100%;
    /* height: 10vh; */
    background: var(--primary-color);
    z-index: -1
}

.dashboard .card {
    width: 90vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    background: #fff;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    box-shadow: 0 0 2px black;
}

.dashboard .card .card-section {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.dashboard .card #title{
    margin-bottom: 2rem;
}

.dashboard .card #subtitle{
    width: 65%;
    margin: 0 auto;
    color: var(--title-color);
    padding-bottom: .6rem;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
}

.dashboard .card .card-section p {
    color: #5c5c5c;
    line-height: 1.4rem;
}

.dashboard .card .card-section #evidence-section {
    padding: 1rem;
    border: 4px solid var(--primary-color);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    font-weight: 600;
}

h1 {
    min-height: 200px;
    font-size: 200px;
}