.box {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    align-items: center;
    justify-content: center;
    /* background-color: #2874c0;  */
    color: rgb(231, 231, 231);
    cursor: pointer;
    transition: .3s;
}

.box:hover {
    transform: scale(1.1);
}

.box .animate__animated.animate__fadeIn:nth-child(2) {
    background-color: red;
}
