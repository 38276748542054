.homepagemenu {
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.homepagemenu h3, .homepagemenu h4 {
  /* color: #444444; */
  color: #ffffff;
  margin-bottom: 2rem !important;
  text-align: center;
}

.homepagemenu .homepagebanner {
  background-color: #0000005d;
  border-radius: .7rem;
  transition: .3s;
}

.homepagemenu .homepagemenufooter {
  color: #e4e4e4;
  font-size: .8rem;
}

.menuSection {
  height: 100%;
}